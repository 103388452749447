<template>
  <v-chip
    v-if="
      item[tableHeader.value].ShowAsLabel &&
      item[tableHeader.value].LabelHasColor &&
      value
    "
    :color="item[tableHeader.value].LabelColor"
    :text-color="item[tableHeader.value].TextColorWhite ? 'white' : ''"
    dense
  >
    {{ value }}
  </v-chip>
  <v-chip v-else-if="item[tableHeader.value].ShowAsLabel && value" dense>
    {{ value }}
  </v-chip>
  <p class="mb-0" v-else>
    {{ value }}
  </p>
</template>

<script>
export default {
  props: {
    item: {
      typeof: Object,
    },
    tableHeader: {
      typeof: Object,
    },
    value: {},
  },
};
</script>

<style>
</style>