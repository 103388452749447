<template>
  <div>
    <!-- List repeating page items -->
    <v-layout v-if="!editMode" class="mt-4">
      <v-menu
        bottom
        :close-on-content-click="false"
        transition="slide-y-transition"
        offset-y
        class="mt-2"
        :nudge-top="-8"
        v-if="pageHasFilters"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            depressed
            class="mb-2 text-capitalize"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon left>mdi-tune-variant</v-icon>
            {{ $t("Filter") }}
            <v-badge
              v-if="amountOfActiveFilters > 0"
              :content="amountOfActiveFilters"
              :value="amountOfActiveFilters"
              color="primary"
              inline
            >
            </v-badge>
          </v-btn>
        </template>

        <v-list class="pb-6">
          <v-list-item
            v-for="(item, key, index) in getPageContentDefinition"
            :key="index"
            class="my-2"
          >
            <date-picker
              v-if="item['Type'] == 'datepicker'"
              name="dateFilterMenu"
              :lableName="item.Title"
              :dateOptions="{
                dateClearable: true,
                dateDisabled: false,
                dataFieldValidation: item.FieldValidation,
                dateAutoSelectCurrent: false,
                dateRules: null,
                dateIsFilter: true,
                dateSingleLine: item.SigleLine,
              }"
              :dateValue="item.Value"
              @clearDate="filterChanged(undefined, key)"
              @changeDatePicker="filterChanged($event, key)"
            />
            <time-picker-component
              v-else-if="item['Type'] == 'time'"
              :lableName="item.Title"
              :timeValue="timeFilterValue"
              :timeOptions="{
                timeClearable: true,
                timeDisabled: false,
                timeFieldValidation: item.FieldValidation,
                timeRules: null,
                showSeconds: item.ShowSeconds,
                timeSingelLine: item.SigleLine,
              }"
              @changedTime="filterChanged($event, key)"
              @clearTime="filterChanged(undefined, key)"
            />
            <v-select
              v-else-if="item['Type'] == 'boolean'"
              :items="getBooleanValues()"
              clearable
              :label="getTranslatedItemTitle(item)"
              item-value="value"
              item-text="label"
              @change="filterChanged($event, key)"
              hide-details="auto"
              dense
              outlined
            >
            </v-select>
            <v-select
              v-else-if="item['Type'] == 'dropdown'"
              :items="item.Options"
              clearable
              :label="getTranslatedItemTitle(item)"
              item-value="Id"
              item-text="Value"
              @change="filterChanged($event, key)"
              hide-details="auto"
              dense
              outlined
            ></v-select>

            <v-layout align-center v-else-if="item['Type'] == 'content'">
              <v-autocomplete
                :ref="'autocomplete' + key"
                :items="contentFilterSearchResults[key]"
                :loading="searchingContentFilters[key]"
                :search-input.sync="searchContentFilterModels[key]"
                :placeholder="
                  item.LoadItemsWhenCreated ? '' : $t('Search') + '...'
                "
                :multiple="item.MultipleItems"
                :return-object="item.MultipleItems"
                :chips="item.MultipleItems"
                :deletable-chips="item.MultipleItems"
                :item-text="showItem"
                item-value="_id"
                clearable
                :label="getTranslatedItemTitle(item)"
                hide-no-data
                @keyup="searchContentFilter(item.LinkedPage, key)"
                @change="filterChanged($event, key)"
                height="32px"
                small-chips
                hide-details="auto"
                dense
                outlined
              >
              </v-autocomplete>
            </v-layout>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <div class="d-flex mb-2">
        <v-menu
          offset-y
          :close-on-content-click="false"
          v-if="page.AddNotEmptyFilters"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn
                  depressed
                  :color="getColor"
                  v-bind="attrs"
                  v-on="{ ...tooltip, ...menu }"
                  height="30"
                  :class="
                    page.AddSearchField
                      ? ' mr-2 mt-1 px-0 w-small'
                      : ' mt-1 px-0 w-small'
                  "
                >
                  <v-icon> mdi-circle-off-outline </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("Select fields that cannot be empty") }}</span>
            </v-tooltip>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in tableHeadersForFilter"
              :key="index"
            >
              <template v-slot:default="{ active }">
                <v-list-item-action>
                  <v-checkbox
                    v-model="tableNotEmptyFilters[item.text]"
                    @change="searchContent"
                    :input-value="active"
                  >
                    <template v-slot:label>
                      <p class="ml-2 mb-0">{{ item.text }}</p>
                    </template>
                  </v-checkbox>
                </v-list-item-action>
              </template>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-text-field
          class="pt-0 search-field-width"
          v-if="page.AddSearchField"
          :label="$t('Search')"
          clearable
          append-icon="mdi-magnify"
          @click:clear="onSearchCleared()"
          @keyup="searchContent"
          v-model="searchValue"
          hide-details="auto"
          outlined
          single-line
          dense
        >
        </v-text-field>
      </div>
    </v-layout>

    <template v-if="!editMode">
      <v-card outlined>
        <div v-if="page.Visualization == 'rows' || !page.Visualization">
          <v-row
            v-for="(item, index) in content"
            :key="item.DisplayLabel"
            no-gutters
          >
            <v-col
              subheading
              lg="2"
              sm="3"
              xs="3"
              class="pt-4 pl-3 pb-3"
              v-if="
                userIsAdministrator ||
                (page.ShowSortButtons &&
                  $store.getters.hasUserRoleForPageItem({
                    role: 1,
                  }))
              "
            >
              <v-btn
                depressed
                class="edit-page-action-button sort-button"
                text
                small
                icon
                color="grey"
                @click="moveUp(index)"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>

              <v-btn
                depressed
                class="edit-page-action-button sort-button"
                text
                small
                icon
                color="grey"
                @click="moveDown(index)"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </v-col>
            <v-col
              v-if="
                userIsAdministrator ||
                (page.ShowSortButtons &&
                  $store.getters.hasUserRoleForPageItem({
                    role: 1,
                  }))
              "
              lg="8"
              sm="6"
              xs="4"
              class="text-xs-right align-center"
            >
              <v-layout align-center fill-height>
                <v-list-item-title
                  v-html="item.DisplayLabel"
                ></v-list-item-title>
              </v-layout>
            </v-col>
            <v-col v-else lg="10" sm="9" xs="7" class="pl-3 text-xs-right">
              <v-list-item-title v-html="item.DisplayLabel"></v-list-item-title>
            </v-col>
            <v-col lg="2" sm="3" xs="4" class="pa-3 subheading text-right">
              <router-link
                :to="{
                  name: 'editPageAddEditItem',
                  params: { id: page._id.$oid, itemId: item._id.$oid },
                }"
              >
                <v-btn depressed icon color="primaryorange">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </router-link>

              <v-btn
                depressed
                icon
                color="primaryred"
                @click="setDeletingItem(page._id.$oid, item._id.$oid, index)"
                :loading="loadinItemToDelete[index]"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12">
              <v-divider v-if="index + 1 != content.length"></v-divider>
            </v-col>
          </v-row>
        </div>
        <div v-else-if="page.Visualization == 'table'">
          <v-data-table
            :headers="tableHeaders"
            :header-props="{ sortByText: $t('Sort by') }"
            :items="tableRows"
            :no-data-text="$t('No data available')"
            :footer-props="{
              itemsPerPageOptions: [-1],
              pageText: '',
              nextIcon: '',
              prevIcon: '',
            }"
            item-key="Value"
            :options.sync="options"
            :custom-sort="customSort"
            @click:row="handleClick"
            @click:row.ctrl="handleClick"
            @click.native.ctrl="handleClick"
            :class="page.MakeRowsClickable ? 'cursor-pointer' : ''"
          >
            <template
              v-for="(tableHeader, index) in tableHeaders"
              v-slot:[`item.${tableHeader.value}`]="{ item }"
            >
              <div v-if="item[tableHeader.value]" :key="index">
                <template v-if="item[tableHeader.value].AddToTableView">
                  <template v-if="item[tableHeader.value].Type === 'dropdown'">
                    <v-chip
                      v-if="
                        item[tableHeader.value].ShowAsLabel &&
                        getTableDropdown(item[tableHeader.value]) != ''
                      "
                      :text-color="
                        getTableDropdown(item[tableHeader.value]).TextColorWhite
                          ? 'white'
                          : ''
                      "
                      :color="
                        getTableDropdown(item[tableHeader.value]).Color
                          ? getTableDropdown(item[tableHeader.value]).Color
                          : ''
                      "
                      dense
                    >
                      {{ getTableDropdown(item[tableHeader.value]).Value }}
                    </v-chip>
                    <div v-else>
                      {{ getTableDropdown(item[tableHeader.value]).Value }}
                    </div>
                  </template>

                  <template
                    v-else-if="item[tableHeader.value].Type === 'boolean'"
                  >
                    <ShowAsLabel
                      :item="item"
                      :tableHeader="tableHeader"
                      :value="
                        item[tableHeader.value].Value
                          ? $i18n.translate('Yes')
                          : $i18n.translate('No')
                      "
                    />
                  </template>
                  <template
                    v-else-if="item[tableHeader.value].Type === 'content'"
                  >
                    <!-- <v-chip
                    v-if="item[tableHeader.value].ShowAsLabel"
                    :color="
                    item[tableHeader.value].ColorLinkedPage.value
                    .Value
                              "
                              >
                              {{ item[tableHeader.value] }}
                            </v-chip> -->
                    <v-layout fill-height align-center justify-start>
                      <template v-if="item[tableHeader.value].MultipleItems">
                        <template
                          v-for="(val, index) in getTableContentItems(
                            item[tableHeader.value]
                          )"
                        >
                          {{ getValue(index, val, item[tableHeader.value]) }}
                          <v-btn
                            depressed
                            :key="index"
                            v-if="
                              item[tableHeader.value].ShowLinkToItems &&
                              $helpers.checkGoToLinkedContent(
                                userIsAdministrator,
                                item[tableHeader.value].LinkedContent[index],
                                item[tableHeader.value],
                                selectedWebsite.Pages
                              )
                            "
                            class="ml-1"
                            right
                            x-small
                            icon
                            @click="
                              $helpers.goToLinkedContentPage(
                                item[tableHeader.value],
                                item[tableHeader.value].LinkedPage.$oid,
                                item[tableHeader.value].LinkedContent[index]
                                  .$oid
                              )
                            "
                          >
                            <v-icon> mdi-arrow-right </v-icon>
                          </v-btn>
                        </template>
                      </template>
                      <template v-else>
                        {{ getContentItem(item[tableHeader.value]) }}
                        <v-btn
                          depressed
                          v-if="
                            item[tableHeader.value].ShowLinkToItems &&
                            $helpers.checkGoToLinkedContent(
                              userIsAdministrator,
                              item[tableHeader.value].LinkedContent,
                              item[tableHeader.value],
                              selectedWebsite.Pages
                            )
                          "
                          class="ml-1"
                          right
                          x-small
                          icon
                          @click="
                            $helpers.goToLinkedContentPage(
                              item[tableHeader.value]
                            )
                          "
                        >
                          <v-icon> mdi-arrow-right </v-icon>
                        </v-btn>
                      </template>
                    </v-layout>
                  </template>
                  <template
                    v-else-if="item[tableHeader.value].Type === 'number'"
                  >
                    <ShowAsLabel
                      :item="item"
                      :tableHeader="tableHeader"
                      :value="getTableNumber(item[tableHeader.value])"
                    />
                  </template>
                  <template
                    v-else-if="item[tableHeader.value].Type === 'colorpicker'"
                  >
                    <ShowAsLabel
                      :item="item"
                      :tableHeader="tableHeader"
                      :value="item[tableHeader.value].Value"
                    />
                  </template>
                  <template
                    v-else-if="item[tableHeader.value].Type === 'datepicker'"
                  >
                    <ShowAsLabel
                      :item="item"
                      :tableHeader="tableHeader"
                      :value="getTableDatepicker(item[tableHeader.value])"
                    />
                  </template>
                  <template v-else-if="item[tableHeader.value].Type === 'url'">
                    <v-chip
                      v-if="
                        item[tableHeader.value].ShowAsLabel &&
                        item[tableHeader.value].LabelHasColor
                      "
                      :color="item[tableHeader.value].LabelColor"
                      :text-color="
                        item[tableHeader.value].TextColorWhite ? 'white' : ''
                      "
                      @click="openInNewTab(item[tableHeader.value].Value)"
                      dense
                    >
                      <v-layout fill-height align-center justify-start>
                        <v-img
                          contain
                          max-height="16"
                          max-width="16"
                          :src="getFavicon(item[tableHeader.value])"
                          class="mr-2"
                        >
                        </v-img>
                        <p
                          class="mb-0"
                          :class="
                            item[tableHeader.value].Value
                              ? 'text-decoration-underline cursor-p'
                              : ''
                          "
                          @click="openInNewTab(item[tableHeader.value].Value)"
                        >
                          {{
                            getDomainUrl(
                              item[tableHeader.value].Value,
                              item[tableHeader.value]
                            )
                          }}
                        </p>
                      </v-layout>
                    </v-chip>
                    <v-chip
                      v-else-if="item[tableHeader.value].ShowAsLabel"
                      dense
                      @click="openInNewTab(item[tableHeader.value].Value)"
                    >
                      <v-layout fill-height align-center justify-start>
                        <v-img
                          contain
                          max-height="16"
                          max-width="16"
                          :src="getFavicon(item[tableHeader.value])"
                          class="mr-2"
                        >
                        </v-img>
                        <p
                          class="mb-0"
                          :class="
                            item[tableHeader.value].Value
                              ? 'text-decoration-underline cursor-p'
                              : ''
                          "
                        >
                          {{
                            getDomainUrl(
                              item[tableHeader.value].Value,
                              item[tableHeader.value]
                            )
                          }}
                        </p>
                      </v-layout>
                    </v-chip>
                    <p
                      v-else
                      class="mb-0"
                      @click="openInNewTab(item[tableHeader.value].Value)"
                    >
                      <v-layout fill-height align-center justify-start>
                        <v-img
                          contain
                          max-height="16"
                          max-width="16"
                          :src="getFavicon(item[tableHeader.value])"
                          class="mr-2"
                        >
                        </v-img>
                        <p
                          class="mb-0"
                          :class="
                            item[tableHeader.value].Value
                              ? 'text-decoration-underline cursor-p'
                              : ''
                          "
                        >
                          {{
                            getDomainUrl(
                              item[tableHeader.value].Value,
                              item[tableHeader.value]
                            )
                          }}
                        </p>
                      </v-layout>
                    </p>
                  </template>
                  <template
                    v-else-if="item[tableHeader.value].Type === 'files'"
                  >
                    <div>
                      <v-layout
                        fill-height
                        align-center
                        justify-start
                        v-for="(file, index) in getAllFilesFromItem(
                          item[tableHeader.value],
                          item
                        )"
                        :key="index"
                      >
                        <!-- <p v-if="item[tableHeader.value].FileAlias">
                          {{ item[tableHeader.value].FileAlias[file] }}
                        </p> -->
                        <p class="mb-0" v-if="file.alias[currentLanguage]">
                          {{ file.alias[currentLanguage] }}
                        </p>

                        <p
                          class="mb-0"
                          v-else-if="file.alias[currentLanguage] == ''"
                        >
                          {{
                            substringText(item[tableHeader.value], file.name)
                          }}
                        </p>
                        <p class="mb-0" v-else>
                          {{ file.name }}
                        </p>

                        <v-btn
                          depressed
                          color="primary"
                          icon
                          x-small
                          class="ml-1"
                          @click="$set(showPreview, file.name + file.id, true)"
                          v-if="
                            !showPreview[file.name + file.id] &&
                            (file.name.includes('.pdf') ||
                              file.name.includes('.mp4'))
                          "
                        >
                          <v-icon>mdi-eye</v-icon>
                        </v-btn>
                        <v-btn
                          depressed
                          :loading="isDownloading[file.name + file.id]"
                          color="primary"
                          icon
                          x-small
                          @click="downloadFile(file.name, file.id)"
                        >
                          <v-icon>mdi-file-download</v-icon>
                        </v-btn>
                        <pageElementFilesDialog
                          :key="page.WebsiteId.$oid + file.name"
                          :websiteId="page.WebsiteId.$oid"
                          :showPreviewItem="file.name"
                          :showPreviewProp="showPreview[file.name + file.id]"
                          @update:showPreview="
                            $set(showPreview, file.name + file.id, $event)
                          "
                        />
                      </v-layout>
                    </div>
                  </template>
                  <template v-else>
                    <ShowAsLabel
                      :item="item"
                      :tableHeader="tableHeader"
                      :value="getTableText(item[tableHeader.value])"
                    />
                  </template>
                </template>
              </div>
            </template>
            <template v-slot:[`item.sort`]="{ index }">
              <v-btn
                depressed
                class="edit-page-action-button sort-button"
                text
                small
                icon
                color="grey"
                @click="moveUp(index)"
              >
                <v-icon>mdi-chevron-up</v-icon>
              </v-btn>

              <v-btn
                depressed
                class="edit-page-action-button sort-button"
                text
                small
                icon
                color="grey"
                @click="moveDown(index)"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <template v-slot:[`item.actions`]="{ item, index }">
              <router-link
                v-if="
                  userIsAdministrator ||
                  ($store.getters.hasUserRoleForPageItem({
                    role: 1,
                  }) &&
                    page.ShowCopyBtnForAllUsers)
                "
                :to="{
                  name: 'editPageAddCopyItem',
                  params: {
                    id: page._id.$oid,
                    itemId: item._id.$oid,
                    isCopy: true,
                  },
                }"
              >
                <v-btn depressed icon color="primary">
                  <v-icon>mdi-plus-box-multiple</v-icon>
                </v-btn>
              </router-link>

              <router-link
                v-if="
                  userIsAdministrator ||
                  ($store.getters.hasUserRoleForPageItem({
                    role: 0,
                  }) &&
                    !$store.getters.hasUserRoleForPageItem({
                      role: 1,
                    }))
                "
                :to="{
                  name: 'editPageViewItem',
                  params: {
                    id: page._id.$oid,
                    itemId: item._id.$oid,
                    isDetail: true,
                  },
                }"
                ><v-btn depressed icon color="primary">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
              </router-link>
              <router-link
                v-if="
                  userIsAdministrator ||
                  $store.getters.hasUserRoleForPageItem({
                    role: 1,
                  })
                "
                :to="{
                  name: 'editPageAddEditItem',
                  params: { id: page._id.$oid, itemId: item._id.$oid },
                }"
              >
                <v-btn depressed icon color="primaryorange">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </router-link>

              <v-btn
                v-if="
                  userIsAdministrator ||
                  $store.getters.hasUserRoleForPageItem({
                    role: 2,
                  })
                "
                depressed
                icon
                color="primaryred"
                @click="setDeletingItem(page._id.$oid, item._id.$oid, index)"
                :loading="loadinItemToDelete[index]"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <template v-if="checkLoadMore && getHasMoreData" #footer>
              <v-layout justify-center align-center fill-height>
                <tr>
                  <td :colspan="tableHeaders.length">
                    <v-btn text @click="getContent()" class="my-2">
                      {{ $t("Load More") }}
                    </v-btn>
                  </td>
                </tr>
              </v-layout>
            </template>
          </v-data-table>
        </div>
      </v-card>

      <preloader v-if="contentIsLoading"></preloader>
      <infinite-loading
        v-if="!checkLoadMore"
        slot="append"
        force-use-infinite-wrapper=".el-table__body-wrapper"
        @infinite="getContent"
        ref="infiniteLoading"
      >
        <div slot="spinner"><preloader></preloader></div>
        <div slot="no-more"></div>
        <div slot="no-results"></div>
      </infinite-loading>
    </template>

    <!-- Edit repeating objects content definition -->
    <template v-if="userIsAdministrator && editMode">
      <v-card outlined class="mb-4">
        <v-card-text>
          <h3 class="mb-3">{{ $t("Visualization") }}</h3>

          <v-radio-group v-model="page.Visualization" mandatory>
            <v-radio :label="$t('Rows')" value="rows"></v-radio>
            <v-radio :label="$t('Table')" value="table"></v-radio>
          </v-radio-group>
        </v-card-text>
      </v-card>

      <v-card outlined class="mb-4">
        <v-card-text>
          <h3 class="mb-3">{{ $t("Edit content definition") }}</h3>
        </v-card-text>

        <v-form ref="contentDefinitionform" lazy-validation>
          <template v-for="(item, index) in page.ContentDefinition">
            <v-layout
              :key="index"
              class="custom-overview-row mt-sm-0 mt-md-4"
              wrap
            >
              <v-row>
                <v-col cols="12" sm="2" md="2" lg="2" xl="2">
                  <v-row no-gutters>
                    <v-layout column class="max-width-layout">
                      <v-btn
                        depressed
                        text
                        small
                        icon
                        color="grey"
                        @click="moveUpContentDefinition(index)"
                      >
                        <v-icon>mdi-chevron-up</v-icon>
                      </v-btn>

                      <v-btn
                        depressed
                        text
                        small
                        icon
                        color="grey"
                        @click="moveDownContentDefinition(index)"
                      >
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </v-layout>
                    <h4
                      class="mt-4 text-right text-sm-left text-md-left text-lg-left text-xl-left pr-0 pr-sm-4 pr-md-4 pr-lg-4 pr-xl-4"
                    >
                      {{ index }}
                    </h4>
                  </v-row>
                </v-col>
                <v-col cols="12" sm="9" md="9" lg="9" xl="9">
                  <v-row no-gutters>
                    <v-spacer></v-spacer>
                    <v-col cols="8" sm="5" md="5" lg="5" class="pl-2 pt-2">
                      <configLanguageSelector
                        :isRepeating="true"
                        :itemData="item"
                        @changeLanguage="selectedLang[index] = $event"
                        @changeTranslatedTitle="item.TranslatedTitle = $event"
                      />

                      <template v-if="item.Type == 'content'">
                        <v-select
                          :items="getSharedPagesNotAssigned()"
                          single-line
                          v-model="item.LinkedPage"
                          item-value="_id"
                          :label="$t('Select a page')"
                          @change="getContentFromLinkedPage(item.LinkedPage)"
                          outlined
                          dense
                          class="mt-4"
                        >
                          <template slot="selection" slot-scope="data">
                            <div class="input-group__selections__comma">
                              {{ data.item.Name[currentLanguage] }}
                            </div>
                          </template>
                          <template slot="item" slot-scope="data">
                            {{ data.item.Name[currentLanguage] }}
                          </template>
                        </v-select>
                      </template>
                      <template v-else-if="item.Type == 'croppedimage'">
                        <v-row no-gutters class="mt-4">
                          <v-col
                            cols="12"
                            xs="12"
                            sm="12"
                            md="12"
                            lg="6"
                            xl="6"
                          >
                            <v-text-field
                              :label="$t('Width in pixels')"
                              v-model="item.Width"
                              class="mr-md-3 mr-lg-3 mr-xl-3"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            xs="12"
                            sm="12"
                            md="12"
                            lg="6"
                            xl="6"
                          >
                            <v-text-field
                              :label="$t('Height in pixels')"
                              v-model="item.Height"
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </template>
                      <template v-else-if="item.Type == 'colorpicker'">
                        <v-color-picker
                          v-model="item.Value"
                          class="mt-4"
                          dot-size="25"
                          mode="hexa"
                          swatches-max-height="200"
                        ></v-color-picker>
                      </template>

                      <template v-if="item.Type == 'dropdown'">
                        <v-row no-gutters>
                          <v-col cols="12" class="my-4">
                            <b>
                              <i> {{ $t("Configure dropdown options") }}: </i>
                            </b>
                          </v-col>
                          <v-col class="mb-4">
                            <v-chip
                              v-for="(option, optionIndex) in item.Options"
                              :key="optionIndex"
                              close
                              @click:close="removeOption(index, optionIndex)"
                              :text-color="option.TextColorWhite ? 'white' : ''"
                              class="mr-1"
                              :color="option.Color ? option.Color : ''"
                            >
                              <v-menu offset-y :close-on-content-click="false">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" class="mr-1">
                                    mdi-palette
                                  </v-icon>
                                </template>
                                <v-list>
                                  <v-list-item>
                                    <v-switch
                                      flat
                                      :ripple="false"
                                      v-model="option.TextColorWhite"
                                      :label="$t('Text color white')"
                                    ></v-switch>
                                  </v-list-item>
                                  <v-list-item>
                                    <v-color-picker
                                      v-model="option.Color"
                                      dot-size="25"
                                      hide-mode-switch
                                      mode="hexa"
                                      swatches-max-height="200"
                                    ></v-color-picker>
                                  </v-list-item>
                                </v-list>
                              </v-menu>
                              {{ option.Color }}
                              <p class="mb-0">{{ option.Value }}</p>
                            </v-chip>
                          </v-col>
                          <v-col cols="12">
                            <v-row no-gutters>
                              <v-col cols="12">
                                <v-text-field
                                  :label="$t('Option name')"
                                  v-model="newOptionName"
                                  :error="addOptionFieldHasError"
                                  :rules="addOptionFieldRule"
                                  class="no-styling ml-1"
                                  hide-details="auto"
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-col>
                              <v-col cols="12" class="mb-4">
                                <v-btn
                                  depressed
                                  class="mt-3 white--text"
                                  color="primarygreen"
                                  @click="addOption(index)"
                                >
                                  {{ $t("Add option") }}
                                  <v-icon right>mdi-plus</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </template>
                    </v-col>
                    <v-col cols="4" sm="2" md="2" lg="2" class="pl-3">
                      <h4
                        class="mt-4 mb-4 text-right text-sm-left text-md-left text-lg-left text-xl-left pr-0 pr-md-4 pr-lg-4 pr-xl-4"
                      >
                        {{ item.Type }}
                      </h4>
                      <p
                        v-if="item.Type == 'colorpicker'"
                        class="mt-6 mb-4 ml-4"
                      >
                        {{ $t("default value") }}
                      </p>
                    </v-col>
                    <v-col cols="12" sm="4" md="4" lg="4">
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="
                          item.Type == 'text' ||
                          item.Type == 'richtext' ||
                          item.Type == 'image' ||
                          item.Type == 'croppedimage' ||
                          item.Type == 'gallery'
                        "
                        v-model="item.Translate"
                        class="mt-3 ml-2 mb-0"
                        light
                        v-bind:label="$t('Translate item')"
                      ></v-switch>

                      <v-switch
                        flat
                        :ripple="false"
                        v-if="
                          item.Type == 'image' ||
                          item.Type == 'croppedimage' ||
                          item.Type == 'gallery'
                        "
                        v-model="item.AddAltTags"
                        class="mt-0 ml-2"
                        :label="$t('Add alt tags')"
                      >
                      </v-switch>

                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type == 'content'"
                        class="mt-0 ml-2"
                        v-model="item.LoadItemsWhenCreated"
                        :label="$t('Load items when created')"
                      ></v-switch>
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type == 'content'"
                        class="mt-0 ml-2"
                        v-model="item.MultipleItems"
                        :label="$t('Multiple items')"
                      ></v-switch>
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type == 'content'"
                        class="mt-0 ml-2"
                        v-model="item.ShowLinkToItems"
                        :label="$t('Show link to item(s) in table overview')"
                      ></v-switch>

                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type == 'time'"
                        class="mt-0 ml-2"
                        v-model="item.ShowSeconds"
                        :label="$t('Show seconds')"
                      ></v-switch>

                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type != 'custom'"
                        class="mt-0 ml-2"
                        v-model="item.ReadOnly"
                        :label="$t('Read-only')"
                      ></v-switch>

                      <v-switch
                        flat
                        :ripple="false"
                        v-if="
                          page.Visualization == 'table' &&
                          (item.Type == 'text' ||
                            item.Type == 'richtext' ||
                            item.Type == 'datepicker' ||
                            item.Type == 'time' ||
                            item.Type == 'dropdown' ||
                            item.Type == 'boolean' ||
                            item.Type == 'content' ||
                            item.Type == 'number' ||
                            item.Type == 'custom' ||
                            item.Type == 'url' ||
                            item.Type == 'colorpicker' ||
                            item.Type == 'files')
                        "
                        v-model="item.AddToTableView"
                        class="mt-0 ml-2"
                        :label="$t('Add to table view')"
                      >
                      </v-switch>
                      <v-divider
                        v-if="item.NeedsDragAndDrop && item.Type == 'files'"
                        class="mb-4"
                      ></v-divider>
                      <v-switch
                        v-if="item.Type == 'files'"
                        v-model="item.NeedsDragAndDrop"
                        class="mt-0 ml-2"
                        :label="$t('Drag and drop')"
                        flat
                        :ripple="false"
                      ></v-switch>
                      <v-row no-gutters v-if="item.Type == 'files'">
                        <v-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                          <v-text-field
                            v-if="item.NeedsDragAndDrop"
                            v-model.number="item.DragAndDropHeight"
                            type="number"
                            min="50"
                            dense
                            outlined
                            hide-details="auto"
                            :label="$t('Drag and drop height')"
                            class="mt-0 ml-2 mr-4 mb-4"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider
                        v-if="
                          (item.ShowAsLabel && item.Type !== 'dropdown') ||
                          (item.NeedsDragAndDrop && item.Type == 'files')
                        "
                        class="mb-4"
                      ></v-divider>
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="
                          page.Visualization == 'table' &&
                          (item.Type == 'datepicker' ||
                            item.Type == 'time' ||
                            item.Type == 'boolean' ||
                            item.Type == 'dropdown' ||
                            item.Type == 'number' ||
                            item.Type == 'url' ||
                            item.Type == 'colorpicker' ||
                            item.Type == 'files')
                        "
                        v-model="item.ShowAsLabel"
                        class="mt-0 ml-2"
                        :label="$t('Show as label')"
                      >
                      </v-switch>
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="
                          page.Visualization == 'table' &&
                          item.ShowAsLabel &&
                          item.Type !== 'dropdown'
                        "
                        v-model="item.LabelHasColor"
                        class="mt-0 ml-2"
                        :label="$t('Label has color')"
                      >
                      </v-switch>
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="
                          page.Visualization == 'table' &&
                          item.ShowAsLabel &&
                          item.LabelHasColor &&
                          item.Type !== 'dropdown'
                        "
                        v-model="item.TextColorWhite"
                        class="mt-0 ml-2"
                        :label="$t('Text color white')"
                      >
                      </v-switch>

                      <v-row
                        v-if="item.ShowAsLabel && item.LabelHasColor"
                        dense
                        class="ml-2"
                      >
                        <v-col
                          cols="1"
                          v-for="(color, index) in colors"
                          :key="index"
                          class="mt-0"
                        >
                          <v-avatar
                            v-if="item.LabelColor == color"
                            color="black"
                            size="26"
                          >
                            <v-avatar
                              @click="$set(item, 'LabelColor', color)"
                              :color="color"
                              class="color-hover"
                              size="24"
                            ></v-avatar>
                          </v-avatar>
                          <v-avatar
                            v-else
                            @click="$set(item, 'LabelColor', color)"
                            :color="color"
                            class="color-hover"
                            size="24"
                          ></v-avatar>
                        </v-col>
                        <v-col cols="12">
                          <v-color-picker
                            v-model="item.LabelColor"
                            mode="hexa"
                            dot-size="25"
                            swatches-max-height="200"
                          ></v-color-picker>
                        </v-col>
                      </v-row>

                      <v-divider
                        v-if="
                          (item.ShowAsLabel && item.Type !== 'dropdown') ||
                          (item.ShowFilter &&
                            (item.Type == 'datepicker' || item.Type == 'time'))
                        "
                        class="mb-4"
                      ></v-divider>

                      <v-switch
                        flat
                        :ripple="false"
                        v-if="
                          item.Type == 'content' ||
                          item.Type == 'dropdown' ||
                          item.Type == 'boolean' ||
                          item.Type == 'datepicker' ||
                          item.Type == 'time'
                        "
                        v-model="item.ShowFilter"
                        class="mt-0 ml-2"
                        :label="$t('Show filter')"
                      >
                      </v-switch>
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="
                          item.ShowFilter &&
                          (item.Type == 'datepicker' || item.Type == 'time')
                        "
                        v-model="item.SigleLine"
                        class="mt-0 ml-2"
                        :label="$t('Sigle line property')"
                      >
                      </v-switch>
                      <v-divider
                        v-if="
                          item.ShowFilter &&
                          (item.Type == 'datepicker' || item.Type == 'time')
                        "
                        class="mb-4"
                      ></v-divider>
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="
                          item.Type == 'text' ||
                          item.Type == 'richtext' ||
                          item.Type == 'datepicker' ||
                          item.Type == 'time' ||
                          item.Type == 'dropdown' ||
                          item.Type == 'boolean' ||
                          item.Type == 'content' ||
                          item.Type == 'number' ||
                          item.Type == 'url' ||
                          item.Type == 'colorpicker' ||
                          item.Type == 'files' ||
                          item.Type == 'image' ||
                          item.Type == 'croppedimage' ||
                          item.Type == 'gallery' ||
                          item.Type == 'codefield' ||
                          item.Type == 'htmlobject'
                        "
                        v-model="item.RemoveCard"
                        class="mt-0 ml-2"
                        :label="$t('Remove frame')"
                      >
                      </v-switch>

                      <!--<v-switch
                        flat :ripple="false"
                                v-if="item.Type == 'text'"
                                v-model="item.Encrypted"
                                class="mt-0 ml-2"
                                :label="$t('Encrypted Field')"
                              >
                              </v-switch>-->
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type == 'datepicker' || item.Type == 'time'"
                        v-model="item.AutoSelectCurrent"
                        :label="
                          item.Type == 'datepicker'
                            ? $t('Auto select date')
                            : $t('Auto select time')
                        "
                        class="mt-0 ml-2"
                      >
                      </v-switch>
                      <v-divider
                        v-if="item.Type == 'number' && item.NeedsNumberSign"
                        class="mb-4"
                      ></v-divider>
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type == 'number'"
                        v-model="item.NeedsNumberSign"
                        :label="$t('Show currency symbol')"
                        class="mt-0 ml-2"
                      >
                      </v-switch>
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type == 'number' && item.NeedsNumberSign"
                        v-model="item.IsPrefix"
                        :label="$t('Show currency symbol in front')"
                        class="mt-0 ml-2"
                      >
                      </v-switch>
                      <v-row no-gutters v-if="item.NeedsNumberSign">
                        <v-col cols="10" class="mt-0 ml-2">
                          <v-select
                            v-model="item.NumberSign"
                            :items="$config.signs"
                            :label="$t('Symbol')"
                            outlined
                            dense
                          ></v-select>
                        </v-col>
                      </v-row>
                      <v-divider
                        class="mb-4"
                        v-if="item.Type == 'number' && item.NeedsNumberSign"
                      ></v-divider>
                      <v-divider
                        v-if="
                          (item.Type == 'text' ||
                            item.Type == 'richtext' ||
                            item.Type == 'files' ||
                            item.Type == 'content' ||
                            item.Type == 'url') &&
                          item.MaxLenght
                        "
                        class="mb-4"
                      ></v-divider>
                      <v-row
                        no-gutters
                        v-if="
                          item.Type == 'text' ||
                          item.Type == 'richtext' ||
                          item.Type == 'files' ||
                          item.Type == 'content' ||
                          item.Type == 'url'
                        "
                      >
                        <v-col cols="12" class="mt-0 ml-2">
                          <v-switch
                            flat
                            :ripple="false"
                            v-model="item.MaxLenght"
                            :label="$t('Max lenght displaylabel')"
                            class="mt-0"
                          >
                          </v-switch>
                        </v-col>
                        <v-col cols="12" md="5" class="mt-0 ml-2">
                          <v-text-field
                            v-if="item.MaxLenght"
                            v-model.number="item.MaxLenghtString"
                            type="number"
                            max="1000"
                            min="0"
                            :label="$t('Character length')"
                            outlined
                            dense
                            hide-details="auto"
                            class="mb-4"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-divider
                        class="mb-4"
                        v-if="
                          (item.Type == 'text' ||
                            item.Type == 'richtext' ||
                            item.Type == 'files' ||
                            item.Type == 'content' ||
                            item.Type == 'url') &&
                          item.MaxLenght
                        "
                      ></v-divider>

                      <v-switch
                        flat
                        :ripple="false"
                        v-if="
                          item.Type == 'text' ||
                          item.Type == 'datepicker' ||
                          item.Type == 'time' ||
                          item.Type == 'dropdown' ||
                          item.Type == 'number'
                        "
                        v-model="item.FieldValidation"
                        @change="$helpers.changeFieldValidation(item)"
                        class="mt-0 ml-2"
                        :label="$t('Field validation')"
                      >
                      </v-switch>
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type == 'files'"
                        class="mt-0 ml-2"
                        v-model="item.TranslatedFiles"
                        :label="$t('Translate files')"
                      ></v-switch>

                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type == 'files'"
                        class="mt-0 ml-2"
                        v-model="item.ShowAlias"
                        :label="$t('File alias')"
                      ></v-switch>
                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type == 'files'"
                        class="mt-0 ml-2"
                        v-model="item.SpecificFile"
                        :label="$t('Set file types')"
                      ></v-switch>

                      <v-switch
                        flat
                        :ripple="false"
                        v-if="item.Type == 'htmlobject'"
                        class="mt-0 ml-2"
                        v-model="item.ReadOnlyCode"
                        :label="$t('Readonly code')"
                      ></v-switch>

                      <v-row no-gutters v-if="item.SpecificFile">
                        <v-col cols="12" lg="6" class="mt-0 ml-2">
                          <v-select
                            :items="$config.fileTypes"
                            v-model="item.SpecificFileTypes"
                            :label="$t('Files')"
                            class="mb-4"
                            multiple
                            chips
                            small-chips
                            clearable
                            outlined
                            dense
                            hide-details="auto"
                          >
                          </v-select>
                        </v-col>
                      </v-row>
                      <v-row no-gutters v-if="item.FieldValidation">
                        <v-col cols="8" class="mt-0 ml-2">
                          <v-combobox
                            :items="$validation.getAllValidationNames()"
                            :label="$t('Rule')"
                            v-model="item.FieldValidationFuntion"
                            clearable
                            @click:clear="item.FieldValidationFuntion = ''"
                            hide-details="auto"
                            outlined
                            dense
                            class="mb-4"
                          >
                          </v-combobox>
                        </v-col>
                      </v-row>
                      <v-row no-gutters>
                        <v-col cols="5" class="mt-0 ml-2">
                          <v-text-field
                            v-model.number="item.colWidth"
                            type="number"
                            max="12"
                            min="1"
                            :label="$t('Column width')"
                            dense
                            outlined
                            hide-details="auto"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="12"
                  sm="1"
                  md="1"
                  lg="1"
                  xl="1"
                  class="d-flex align-center justify-end"
                >
                  <v-btn
                    depressed
                    @click="removeContentDefinitionItem(index)"
                    icon
                    color="primaryred"
                    class="mr-md-3 mr-lg-3 mr-xl-3"
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-layout>
          </template>
        </v-form>

        <v-card-text class="border-top">
          <h3 class="mb-3 mt-5">
            {{ $t("Add element to content definition") }}
          </h3>

          <addRemovePageElement
            @addItem="addObjectToContentDefinition"
            :enableAdd="true"
          />
        </v-card-text>
      </v-card>

      <v-card outlined class="mb-4">
        <v-card-text>
          <h3 class="mb-3">{{ $t("Options") }}</h3>

          <v-switch
            flat
            :ripple="false"
            v-model="page.AddSearchField"
            light
            :label="$t('Add search field')"
          ></v-switch>

          <v-switch
            flat
            :ripple="false"
            v-model="page.AllowExportForAllUsers"
            light
            :label="$t('Allow export for all users')"
          ></v-switch>

          <v-switch
            flat
            :ripple="false"
            v-model="page.ShowSortButtons"
            :disabled="page.MostRecentItemsAtTheTop"
            light
            :label="$t('Show sort buttons')"
          ></v-switch>

          <v-switch
            flat
            :ripple="false"
            v-model="page.AllowBulkUpdateForAllUsers"
            light
            :label="$t('Allow bulk update for all users')"
          ></v-switch>
          <v-switch
            flat
            :ripple="false"
            v-model="page.MostRecentItemsAtTheTop"
            light
            @change="
              page.MostRecentItemsAtTheTop
                ? $set(page, 'ShowSortButtons', false)
                : ''
            "
            :label="$t('Show most recent items at the top')"
          ></v-switch>
          <v-switch
            flat
            :ripple="false"
            v-model="page.ShowCopyBtnForAllUsers"
            light
            :label="$t('Show copy button for all users')"
          ></v-switch>
          <v-switch
            flat
            :ripple="false"
            v-model="page.MakeRowsClickable"
            light
            :label="$t('Make rows clickable')"
          ></v-switch>
          <v-switch
            flat
            :ripple="false"
            v-model="page.AddNotEmptyFilters"
            light
            :label="$t('Show empty items filter')"
          ></v-switch>
          <v-switch
            v-model="page.LoadMoreButtonActive"
            light
            :label="$t(`\'${'Load more'}\' button active`)"
          ></v-switch>
          <v-row no-gutters>
            <v-col cols="12" md="3" class="d-flex">
              <v-text-field
                v-model="page.DisplayLabelCustomFormat"
                :label="$t('Display label custom format')"
                :placeholder="
                  '{'.charAt(0) +
                  `${$t('Name')}` +
                  '}'.charAt(0) +
                  `${$t('propertyname')}` +
                  '{'.charAt(0) +
                  `${$t('Name')}` +
                  '}'.charAt(0) +
                  `${$t('propertyname')}`
                "
                clearable
                outlined
                dense
                hide-details="auto"
              ></v-text-field>

              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="grey"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    class="ml-1"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>

                <span>
                  {{
                    "{".charAt(0) +
                    `${$t("Name")}` +
                    "}".charAt(0) +
                    `${$t("propertyname")}` +
                    "{".charAt(0) +
                    `${$t("Name")}` +
                    "}".charAt(0) +
                    `${$t("propertyname")}`
                  }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card outlined class="mb-4">
        <v-card-text>
          <h3 class="mb-3">{{ $t("Integrations") }}</h3>
          <mailchimp-integration
            :page="page"
            :currentLanguage="currentLanguage"
            @updatePageMailchimpIntegration="page.MailChimpIntegration = $event"
          />
        </v-card-text>
      </v-card>
    </template>

    <template v-if="page.RepeatingContent && editMode">
      <cancelConfirmButtons
        :confirmFunction="saveContentDefinition"
        :loading="savingContentDefinition"
        :confirmLabel="$t('Save page definition')"
        @goBack="$emit('goBack')"
      />
    </template>
    <v-dialog v-model="deleteItemDialog" max-width="600px">
      <v-card outlined>
        <v-card-title>
          {{ $t("Delete") }}
        </v-card-title>
        <v-card-text>
          <span
            v-if="itemToDelete.displayLabel"
            v-html="
              $t('Delete page item', { label: itemToDelete.displayLabel })
            "
          ></span>
          <span v-else>
            {{ $t("Are you sure you want to delete the item?") }}
          </span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn depressed text @click="deleteItemDialog = false">
            {{ $t("Cancel") }}
          </v-btn>
          <v-btn
            depressed
            :loading="deleting"
            color="primaryred"
            class="white--text"
            @click="deletePageItem()"
          >
            {{ $t("Delete") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapGetters } from "vuex";
import PageElementFilesDialog from "../shared/pageElementFilesDialog.vue";
import ShowAsLabel from "../shared/page-helpers/ShowAsLabel.vue";
import ToasterVue from "../shared/app/Toaster.vue";
export default {
  components: { InfiniteLoading, PageElementFilesDialog, ShowAsLabel },
  props: {
    page: {
      type: [Object, Array],
    },
  },
  data() {
    return {
      userIsAdministrator: this.$auth.userIsAdministrator(),
      contentFilterSearchResults: [],
      searchingContentFilters: [],
      searchContentFilterModels: [],
      options: {},
      searchValue: "",
      tableSortBy: "",
      timeFilterValue: null,
      content: null,
      loadinItemToDelete: [],
      deleteItemDialog: false,
      itemToDelete: {},
      deleting: false,
      savingContentDefinition: false,
      tableSortDescending: false,
      addOptionFieldHasError: false,
      newOptionName: "",
      addOptionFieldRule: [],
      setSyncPageSnackbar: false,
      pageFilters: [],
      selectedLang: {},
      showPreview: [],
      isDownloading: [],
      infiniteId: 0,
      tableNotEmptyFilters: [],
      colors: this.$config.colors,
      activeFilters: {},
      contentIsLoading: false
    };
  },
  created() {
    if (this.checkLoadMore) {
      this.getContent();
    }
    //Get content filter data
    for (let value in this.page.ContentDefinition) {
      if (
        this.page.ContentDefinition[value].Type == "content" &&
        this.page.ContentDefinition[value].LoadItemsWhenCreated
      ) {
        this.searchingContentFilters[value] = false;
        this.searchContentFilterModels[value] = "";
        this.searchContentFilter(
          this.page.ContentDefinition[value].LinkedPage,
          value
        );
      }
    }
    if (
      Object.keys(this.page.ContentDefinition).length == 0 &&
      !this.editMode
    ) {
      const toaster = {
        message: this.$t("This page has not been configured yet"),
        timeout: false,
        type: "INFO",
        toastClassName: ["toaster-color-info", "toaster-layout"],
        bodyClassName: ["toaster-text-layout"],
        icon: "mdi-information",
      };
      this.$store.dispatch("setToaster", toaster);
    }
  },
  computed: {
    ...mapGetters({
      selectedWebsite: "selectedWebsite",
      editMode: "editMode",
    }),
    amountOfActiveFilters() {
      const keys = Object.keys(this.activeFilters);

      return keys.length;
    },
    checkLoadMore() {
      if (!this.page.LoadMoreButtonActive) {
        return false;
      }
      return this.page.LoadMoreButtonActive;
    },
    getColor() {
      for (const key in this.tableNotEmptyFilters) {
        if (this.tableNotEmptyFilters[key]) {
          return "primary";
        }
      }
      return "";
    },
    currentLanguage: {
      get() {
        return this.$i18n.locale();
      },
    },
    getHasMoreData() {
      if (!this.content) {
        return false;
      }
      return this.content.length !== this.page.ContentCount;
    },
    tableHeadersForFilter() {
      if (this.page.Visualization == "table" && this.page.ContentDefinition) {
        var headers = [];
        for (let value in this.page.ContentDefinition) {
          if (this.page.ContentDefinition[value].AddToTableView) {
            if (this.page.ContentDefinition[value].TranslatedTitle) {
              if (
                this.page.ContentDefinition[value].TranslatedTitle[
                  this.currentLanguage
                ] == ""
              ) {
                headers.push({
                  text: this.page.ContentDefinition[value].TranslatedTitle[
                    "Nl"
                  ],
                  value: value,
                });
              } else {
                headers.push({
                  text: this.page.ContentDefinition[value].TranslatedTitle[
                    this.currentLanguage
                  ],
                  value: value,
                });
              }
            } else {
              headers.push({
                text: this.page.ContentDefinition[value].Title,
                value: value,
              });
            }
          }
        }
        return headers;
      } else {
        return [];
      }
    },
    tableHeaders() {
      if (this.page.Visualization == "table" && this.page.ContentDefinition) {
        var headers = [];

        if (
          this.userIsAdministrator ||
          (this.page.ShowSortButtons &&
            this.$store.getters.hasUserRoleForPageItem({
              role: 1,
            }))
        ) {
          headers.push({
            text: this.$i18n.translate("Sort"),
            value: "sort",
            sortable: false,
          });
        }

        for (let value in this.page.ContentDefinition) {
          if (this.page.ContentDefinition[value].AddToTableView) {
            if (this.page.ContentDefinition[value].TranslatedTitle) {
              if (
                this.page.ContentDefinition[value].TranslatedTitle[
                  this.currentLanguage
                ] == ""
              ) {
                headers.push({
                  text: this.page.ContentDefinition[value].TranslatedTitle[
                    "Nl"
                  ],
                  value: value,
                });
              } else {
                headers.push({
                  text: this.page.ContentDefinition[value].TranslatedTitle[
                    this.currentLanguage
                  ],
                  value: value,
                });
              }
            } else {
              headers.push({
                text: this.page.ContentDefinition[value].Title,
                value: value,
              });
            }
          }
        }

        headers.push({
          text: this.$i18n.translate("Actions"),
          value: "actions",
          sortable: false,
          align: "right",
        });
        return headers;
      } else {
        return [];
      }
    },
    tableRows() {
      if (this.page.Visualization == "table" && this.content) {
        let rows = [];
        for (let value in this.content) {
          let row = {};
          row["_id"] = this.content[value]._id;
          row["LastUpdated"] = this.content[value].LastUpdated.$date;
          for (let dataValue in this.content[value].Data) {
            row[dataValue] = {
              ...this.content[value].Data[dataValue],
              AddToTableView:
                this.content[value].Data[dataValue].AddToTableView,
            };
          }
          rows.push(row);
        }
        return rows;
      } else {
        return [];
      }
    },
    getPageContentDefinition() {
      let pageContent = {};
      for (const key in this.page.ContentDefinition) {
        if (
          this.page.ContentDefinition[key].ShowFilter &&
          this.page.Visualization == "table"
        ) {
          pageContent[key] = this.page.ContentDefinition[key];
        }
      }

      return pageContent;
    },
    pageHasFilters() {
      const pageContent = this.getPageContentDefinition;
      const keys = Object.keys(pageContent);
      if (keys.length) {
        return true;
      }
      return false;
    },
  },
  watch: {
    options: {
      handler() {
        if (this.content) {
          this.content = null;
          if (!this.checkLoadMore) {
            this.$refs.infiniteLoading.stateChanger.reset();
          }
          this.getContent();
        }
      },
    },
    setSyncPageSnackbar: {
      handler() {
        //todo handeler for this item
        if (this.setSyncPageSnackbar) {
          const content = {
            component: ToasterVue,

            props: {
              message: this.$t(
                "Do you wish to synchronize the content with the latest page definitions?"
              ),
            },
            listeners: {
              syncItems: () => {
                this.$emit("setDialog");
                this.$emit("syncAllItems");

                this.setSyncPageSnackbar = false;
              },
            },
          };
          const options = {
            icon: {
              iconClass: `mdi mdi-information icon-font-size mrgin-icon-toaster`,
              iconTag: "span",
            },
            timeout: false, // can be false, this means it has no timout
          };
          this.$toast(content, options);
        }
      },
    },
  },

  methods: {
    substringText(item, val) {
      return val.substr(0, item.MaxLenghtString) + "...";
    },
    getValue(index, val, item) {
      const itemsLenght = this.getTableContentItems(item).length - 1;
      if (val.substr(0, 1) == " ") {
        val = val.substr(1, val.length);
      }
      if (val.substr(val.length - 1, 1) == " ") {
        val = val.substr(0, val.length - 1);
      }

      if (itemsLenght == 0) {
        if (item.MaxLenght && val.length > item.MaxLenghtString) {
          return this.substringText(item, val);
        } else {
          return val;
        }
      }
      if (item.MaxLenght && val.length > item.MaxLenghtString) {
        return index !== itemsLenght
          ? this.substringText(item, val) + ","
          : this.substringText(item, val);
      } else {
        return index !== itemsLenght ? val + "," : val;
      }
    },
    getTableContentItems(item) {
      if (item.ValueSorts) {
        if (item.ValueSorts[this.currentLanguage]) {
          return item.ValueSorts[this.currentLanguage].split(",");
        }
      }
      if (!item.ValueSort) return [];
      if (item.ValueSort) {
        return item.ValueSort.split(",");
      }
    },
    getAllFilesFromItem(obj, item) {
      if (!obj.Value) return [];

      const allFiles = [];
      const langValues = obj.Value[this.currentLanguage] || obj.Value;
      if (Array.isArray(langValues)) {
        for (const element of langValues) {
          if (obj.ShowAlias && obj.FileAlias && obj.FileAlias[element]) {
            if (typeof obj.FileAlias === "string" && obj.FileAlias !== null) {
              const item = obj.FileAlias[element];
              obj.FileAlias[element] = {
                Nl: item,
                Fr: "",
                En: "",
              };
            }
            const alias = obj.FileAlias[element];
            const maxLength = obj.MaxLenghtString;

            allFiles.push({
              id: item._id.$oid,
              name: element,
              alias: {
                Nl:
                  maxLength && alias["Nl"] && alias["Nl"].length > maxLength
                    ? this.substringText(obj, alias["Nl"])
                    : alias["Nl"],
                Fr:
                  maxLength && alias["Fr"] && alias["Fr"].length > maxLength
                    ? this.substringText(obj, alias["Fr"])
                    : alias["Fr"],
                En:
                  maxLength && alias["En"] && alias["En"].length > maxLength
                    ? this.substringText(obj, alias["En"])
                    : alias["En"],
              },
            });
          } else if (obj.MaxLenght) {
            allFiles.push({
              id: item._id.$oid,
              name: element,
              alias: {
                Nl: this.substringText(obj, element),
                Fr: this.substringText(obj, element),
                En: this.substringText(obj, element),
              },
            });
          } else {
            allFiles.push({
              id: item._id.$oid,
              name: element,
              alias: "",
            });
          }
        }
      }
      return allFiles;
    },
    handleClick(row, event2, nativeEvent) {
      if (nativeEvent.target.classList.contains("v-icon")) return;
      if (this.page.MakeRowsClickable) {
        this.$router.push({
          name: "editPageAddEditItem",
          params: { id: this.page._id.$oid, itemId: row._id.$oid },
        });

        this.$router.go(1);
      }
    },
    addObjectToContentDefinition(properties) {
      var newObject = {
        Title: properties.objectTitle,
        Value: this.$helpers.createEmptyValueBasedOnType(properties.objectType),
        Type: properties.objectType,
        Translate: properties.objectLanguages,
        Options: [],
        Properties: [],
      };
      if (
        properties.objectType == "text" ||
        properties.objectType == "datepicker" ||
        properties.objectType == "time" ||
        properties.objectType == "dropdown" ||
        properties.objectType == "content"
      ) {
        this.$set(newObject, "FieldValidation", true);
        this.$set(newObject, "FieldValidationFuntion", "notEmptyStringRule");
      }

      if (
        properties.objectType == "image" ||
        properties.objectType == "croppedimage" ||
        properties.objectType == "gallery"
      ) {
        newObject["AddAltTags"] = "true";
      }

      this.$set(this.page.ContentDefinition, properties.objectName, newObject);
    },
    addOption(index) {
      this.addOptionFieldHasError = false;
      this.addOptionFieldRule = [];
      if (this.newOptionName.length > 1) {
        this.page.ContentDefinition[index].Options.push({
          Id: this.page.ContentDefinition[index].Options.length + 1,
          Value: this.newOptionName,
          Color: "",
        });
      } else {
        this.addOptionFieldRule = [
          () => this.$i18n.translate("Minimum required characters is") + " 2",
        ];
        this.addOptionFieldHasError = true;
      }
    },
    moveUp(index) {
      var newSort;
      var oldSort = this.content[index].Sort;

      if (index == 0) {
        newSort = this.content[this.content.length - 1].Sort;
      } else {
        newSort = this.content[index - 1].Sort;
      }

      if (newSort == oldSort) {
        newSort += 1;
      }

      var indexToSwapWith = index - 1;

      if (index == 0) {
        indexToSwapWith = this.content.length - 1;
      }

      this.content[index].Sort = newSort;
      this.content[indexToSwapWith].Sort = oldSort;

      this.updateContentSort([
        this.content[index],
        this.content[indexToSwapWith],
      ]);
    },
    moveDown(index) {
      var newSort;
      var oldSort = this.content[index].Sort;

      if (index == this.content.length - 1) {
        newSort = this.content[0].Sort;
      } else {
        newSort = this.content[index + 1].Sort;
      }

      if (newSort == oldSort) {
        newSort -= 1;
      }
      var indexToSwapWith = index + 1;

      if (indexToSwapWith == this.content.length) {
        indexToSwapWith = 0;
      }

      this.content[index].Sort = newSort;
      this.content[indexToSwapWith].Sort = oldSort;

      this.updateContentSort([
        this.content[index],
        this.content[indexToSwapWith],
      ]);
    },
    updateContentSort(items) {
      this.$request.put("/content/updatesort", items, (res) => {
        if (res.success) {
          this.content = this.content.sort(function (a, b) {
            return a.Sort - b.Sort;
          });
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
      });
    },

    getTableDatepicker(item) {
      if (item.Value == "" || !item.Value) {
        return "00-00-0000";
      }
      const [year, month, day] = item.Value.split("-");
      return day + "-" + month + "-" + year;
    },
    getBooleanValues() {
      return [
        { label: this.$i18n.translate("Yes"), value: true },
        { label: this.$i18n.translate("No"), value: false },
      ];
    },
    showItem(item) {
      let returnValue = item;
      if (item["DisplayLabels"]) {
        returnValue = item.DisplayLabels[this.currentLanguage]
          ? item.DisplayLabels[this.currentLanguage]
          : item.DisplayLabels["Nl"];
      } else if (item["DisplayLabel"]) {
        returnValue = item.DisplayLabel;
      }
      return returnValue;
    },
    getTranslatedItemTitle(item) {
      if (!item.TranslatedTitle) {
        return item.Title;
      }
      return item.TranslatedTitle[this.currentLanguage]
        ? item.TranslatedTitle[this.currentLanguage]
        : item.TranslatedTitle["Nl"];
    },
    customSort: function (items) {
      return items;
    },
    moveUpContentDefinition(index) {
      let keyOld;
      let keys = Object.keys(this.page.ContentDefinition);

      keys.forEach((element, i) => {
        if (element == index && i != 0) {
          keyOld = element;
          keys[i] = keys[i - 1];
          keys[i - 1] = keyOld;
        }
      });
      //looping over new item
      let newObject = {};
      keys.forEach((element) => {
        newObject[element] = this.page.ContentDefinition[element];
      });
      this.page.ContentDefinition = newObject;
    },
    moveDownContentDefinition(index) {
      let keyOld;
      let keys = Object.keys(this.page.ContentDefinition);
      let isSet = false;
      keys.forEach((element, i) => {
        if (element == index && i !== keys.length - 1 && !isSet) {
          keyOld = element;
          keys[i] = keys[i + 1];
          keys[i + 1] = keyOld;
          isSet = true;
        }
      });

      //looping over new item
      let newObject = {};
      keys.forEach((element) => {
        newObject[element] = this.page.ContentDefinition[element];
      });
      this.page.ContentDefinition = newObject;
    },
    getSharedPagesNotAssigned() {
      return this.selectedWebsite.Pages.filter(
        (p) => p.RepeatingContent === true
      );
    },
    getContent($state) {
      if (!this.contentIsLoading) {
        this.contentIsLoading = true;
        if (this.options.sortBy) {
          if (this.options.sortBy.length > 0) {
            this.tableSortBy = this.options.sortBy[0];
          }

          if (this.options.sortDesc.length > 0) {
            this.tableSortDescending = this.options.sortDesc[0];
            if (!this.options.sortDesc[0] && this.tableSortBy == "") {
              this.tableSortDescending =
                this.page.MostRecentItemsAtTheTop ?? false;
            }
          } else if (
            this.options.sortBy.length == 0 &&
            this.tableSortBy != ""
          ) {
            this.tableSortDescending = !this.tableSortDescending;
          } else if (
            this.options.sortDesc.length == 0 &&
            this.page.MostRecentItemsAtTheTop &&
            this.tableSortBy == ""
          ) {
            this.tableSortDescending =
              this.page.MostRecentItemsAtTheTop ?? false;
          }
        } else {
          this.tableSortDescending = this.page.MostRecentItemsAtTheTop ?? false;
        }

        let notEmptyFilters = "";
        for (const key in this.tableNotEmptyFilters) {
          if (this.tableNotEmptyFilters[key]) {
            notEmptyFilters += "&notEmptyFilters=" + key + ":" + true;
          }
        }

        var urlParameters =
          this.$route.params.id +
          "?start=" +
          (this.content == null ? 0 : this.content.length) +
          "&sortBy=" +
          this.tableSortBy +
          "&sortDescending=" +
          this.tableSortDescending +
          "&search=" +
          (this.searchValue == null ? "" : this.searchValue) +
          this.$helpers.getPageFiltersQueryString(this.pageFilters) +
          notEmptyFilters +
          "&language=" +
          this.currentLanguage;
        var getContentUrl =
          "/" +
          (this.page.Visualization == "table" ? "" : "minified") +
          "pagecontent/" +
          urlParameters;
        var getContentCountUrl = "/pagecontentcount/" + urlParameters;

        this.$request.getAll(
          [getContentCountUrl, getContentUrl],
          null,
          (res) => {
            if (res[0]) {
              if (!this.checkLoadMore) {
                this.infiniteId += 1;
              }
              if (res[0].success) {
                this.page.ContentCount = res[0].data;
                if (res[1].success) {
                  if (this.content == null) {
                    this.content = res[1].data;
                  } else if (this.content.length < this.page.ContentCount) {
                    this.content = this.content.concat(res[1].data);
                  }
                  if (!this.checkLoadMore) {
                    this.$refs.infiniteLoading.stateChanger.loaded();
                  }
                  if (
                    this.content.length == this.page.ContentCount &&
                    $state &&
                    !this.checkLoadMore
                  ) {
                    this.$refs.infiniteLoading.stateChanger.complete();
                  }

                  if (this.tableSortBy != "" && this.options.sortBy) {
                    this.options.sortDesc[0] = this.tableSortDescending;
                    this.options.sortBy[0] = this.tableSortBy;
                  }

                  this.contentIsLoading = false;
                } else {
                  const toaster = {
                    message: res[1].message,
                    timeout: false,
                    type: "ERROR",
                    toastClassName: ["toaster-color-error", "toaster-layout"],
                    bodyClassName: ["toaster-text-layout"],
                    icon: "mdi-alert-octagon",
                  };
                  this.$store.dispatch("setToaster", toaster);
                  this.contentIsLoading = false;
                }
              } else {
                const toaster = {
                  message: res[0].message,
                  timeout: false,
                  type: "ERROR",
                  toastClassName: ["toaster-color-error", "toaster-layout"],
                  bodyClassName: ["toaster-text-layout"],
                  icon: "mdi-alert-octagon",
                };
                this.$store.dispatch("setToaster", toaster);
                this.contentIsLoading = false;
              }
            } else {
              const toaster = {
                message: res.message,
                timeout: false,
                type: "ERROR",
                toastClassName: ["toaster-color-error", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-alert-octagon",
              };
              this.$store.dispatch("setToaster", toaster);
              this.contentIsLoading = false;
            }
          }
        );
      }
    },
    getTableDropdown(item) {
      if (!item.Value) {
        return "";
      }
      let result = item.Options.filter((obj) => {
        return obj.Id == item.Value;
      });
      if (result.length > 0) {
        return result[0];
      }
      return "";
    },
    getTableText(item) {
      let defaultLanguage = "Nl";
      let dataValueLanguage = !item["Translate"] ? defaultLanguage : this.currentLanguage;
      if (item.Value[dataValueLanguage] == undefined) {
        return item.Value;
      } else {
        if(item.Value[dataValueLanguage] == "")
        {
          dataValueLanguage = defaultLanguage;
        }
        
        let text = item.Value[dataValueLanguage].replace(/<[^>]*>?/gm, "");

        if (
          item.MaxLenght &&
          item.Value[dataValueLanguage] &&
          text.length > item.MaxLenghtString
        ) {
          return this.substringText(item, text);
        } else if (text.length > 80) {
          return text.substr(0, 80) + "...";
        } else {
          return text;
        }
      }
    },
    removeOption(index, optionIndex) {
      this.page.ContentDefinition[index].Options.splice(optionIndex, 1);
    },
    saveContentDefinition() {
      if (this.$refs.contentDefinitionform.validate()) {
        //used when saving the content definition
        this.savingContentDefinition = true;
        //if there is no TranslatedTitle in object add one with title
        for (const key in this.page.ContentDefinition) {
          if (!this.page.ContentDefinition[key]["TranslatedTitle"]) {
            this.page.ContentDefinition[key]["TranslatedTitle"] = {
              Nl: "",
              Fr: "",
              En: "",
            };
            this.page.ContentDefinition[key]["TranslatedTitle"][
              this.selectedLang[key]
            ] = this.page.ContentDefinition[key].Title;
          }
        }
        this.$request.put("/page", this.page, (res) => {
          this.savingContentDefinition = false;

          if (res.success) {
            this.$store.dispatch("setEditMode", false);

            if (this.page.ContentCount != 0) {
              this.setSyncPageSnackbar = true;
            }
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        });
      }
    },
    setDeletingItem(id, itemId, index) {
      this.$set(this.loadinItemToDelete, index, true);
      this.$request.get("/content/" + itemId, null, (res) => {
        if (res.success) {
          this.itemToDelete = {
            displayLabel: res.data.DisplayLabel,
            id: id,
            itemId: itemId,
          };
          this.deleteItemDialog = true;
        } else {
          const toaster = {
            message: res.message,
            timeout: false,
            type: "ERROR",
            toastClassName: ["toaster-color-error", "toaster-layout"],
            bodyClassName: ["toaster-text-layout"],
            icon: "mdi-alert-octagon",
          };
          this.$store.dispatch("setToaster", toaster);
        }
        this.$set(this.loadinItemToDelete, index, false);
      });
    },
    getContentFromLinkedPage(item) {
      if (item.$oid) {
        this.$request.get("/page/" + item.$oid, null, (res) => {
          if (res.success) {
            let arr = [];
            for (const key in res.data.ContentDefinition) {
              if (res.data.ContentDefinition[key].Type === "colorpicker") {
                const lang = res.data.ContentDefinition[key].TranslatedTitle[
                  this.currentLanguage
                ]
                  ? res.data.ContentDefinition[key].TranslatedTitle[
                      this.currentLanguage
                    ]
                  : res.data.ContentDefinition[key].TranslatedTitle["Nl"];
                arr.push({ id: key, value: lang });
              }
            }
            this.linkedPageContentItems = arr;
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
        });
      }
    },
    getFavicon(item) {
      return this.$helpers.getFaviconFromGoogle(item);
    },
    getDomainUrl(item, itemObj) {
      return this.$helpers.getDomainUrl(item, itemObj);
    },
    searchContentFilter(linkedPage, index) {
      if (this.searchingContentFilters[index]) return;
      if (!linkedPage || !this.$auth.loggedIn()) return;

      this.searchingContentFilters[index] = true;

      this.$request.get(
        "/pagecontentsearch/" +
          linkedPage.$oid +
          "?searchValue=" +
          this.searchContentFilterModels[index] +
          "&parentPageId=" +
          this.page._id.$oid +
          "&language=" +
          this.currentLanguage,
        null,
        (res) => {
          if (res.success) {
            if (!res.data.length) {
              this.$set(this.searchingContentFilters, index, false);
              return;
            }
            this.$set(this.contentFilterSearchResults, index, res.data);
            if (this.$refs["autocomplete"]) {
              this.$refs["autocomplete" + index][0].cachedItems = [];
            }
          } else {
            const toaster = {
              message: res.message,
              timeout: false,
              type: "ERROR",
              toastClassName: ["toaster-color-error", "toaster-layout"],
              bodyClassName: ["toaster-text-layout"],
              icon: "mdi-alert-octagon",
            };
            this.$store.dispatch("setToaster", toaster);
          }
          this.$set(this.searchingContentFilters, index, false);
        }
      );
    },
    onSearchCleared() {
      this.searchValue = "";
      this.content = null;
      if (!this.checkLoadMore) {
        this.$refs.infiniteLoading.stateChanger.reset();
      }
      this.getContent();
    },
    searchContent() {
      var scope = this;

      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(function () {
        scope.content = null;
        if (!scope.checkLoadMore) {
          scope.$refs.infiniteLoading.stateChanger.reset();
        }
        scope.getContent();

        clearTimeout(this.searchTimeout);
      }, 800);
    },
    filterChanged(value, filterOn) {
      this.checkFilterArray(filterOn, value);
      this.pageFilters[filterOn] = value;
      this.content = null;
      if (!this.checkLoadMore) {
        this.$refs.infiniteLoading.stateChanger.reset();
      }
      this.getContent();
    },
    checkFilterArray(key, value) {
      if (Array.isArray(value)) {
        if (value.length == 0) {
          this.$delete(this.activeFilters, key);
          return;
        }
      }
      if (value === null || value === undefined) {
        this.$delete(this.activeFilters, key);
      } else {
        if (!Object.prototype.hasOwnProperty.call(this.activeFilters, key)) {
          this.$set(this.activeFilters, key, value);
        }
      }
    },
    deletePageItem() {
      this.deleting = true;

      if (
        this.page.SynchronizationDeleteUrl &&
        this.page.SynchronizationDeleteUrl.length > 0
      ) {
        this.$request.delete(
          this.page.SynchronizationDeleteUrl + "/" + this.itemToDelete.itemId,
          null,
          (res) => {
            if (!res.success) {
              const toaster = {
                message: res.message,
                timeout: false,
                type: "ERROR",
                toastClassName: ["toaster-color-error", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-alert-octagon",
              };
              this.$store.dispatch("setToaster", toaster);
            }
            this.$request.delete(
              "/content/" + this.itemToDelete.itemId,
              null,
              (res) => {
                this.deleting = false;

                if (res.success) {
                  const filterdContent = this.content.filter(
                    (el) => el._id.$oid !== this.itemToDelete.itemId
                  );
                  this.content = filterdContent;

                  this.deleteItemDialog = false;
                } else {
                  const toaster = {
                    message: res.message,
                    timeout: false,
                    type: "ERROR",
                    toastClassName: ["toaster-color-error", "toaster-layout"],
                    bodyClassName: ["toaster-text-layout"],
                    icon: "mdi-alert-octagon",
                  };
                  this.$store.dispatch("setToaster", toaster);
                }
              }
            );
          }
        );
      } else {
        this.$request.delete(
          "/content/" + this.itemToDelete.itemId,
          null,
          (res) => {
            this.deleting = false;

            if (res.success) {
              const filterdContent = this.content.filter(
                (el) => el._id.$oid !== this.itemToDelete.itemId
              );
              this.content = filterdContent;
              this.page.ContentCount -= 1;
              this.deleteItemDialog = false;
            } else {
              const toaster = {
                message: res.message,
                timeout: false,
                type: "ERROR",
                toastClassName: ["toaster-color-error", "toaster-layout"],
                bodyClassName: ["toaster-text-layout"],
                icon: "mdi-alert-octagon",
              };
              this.$store.dispatch("setToaster", toaster);
            }
          }
        );
      }
    },
    getTableNumber(item) {
      if (item["IsPercentage"]) {
        return item.Value + " %";
      }
      if (item["NeedsNumberSign"]) {
        if (item["IsPrefix"]) {
          return item.NumberSign + " " + item.Value;
        } else {
          return item.Value + " " + item.NumberSign;
        }
      }
      return item.Value;
    },
    removeContentDefinitionItem(index) {
      this.$delete(this.page.ContentDefinition, index);
    },

    openInNewTab(item) {
      if (!item) return;
      window.open(item, "_blank").focus();
    },
    clearSyncWarning() {
      this.setSyncPageSnackbar = false;
    },
    downloadFile(file, id) {
      this.$set(this.isDownloading, file + id, true);
      this.$request.download(
        "/files/" +
          this.page.WebsiteId.$oid +
          "/" +
          file +
          "?token=" +
          this.$auth.userToken(),
        { responseType: "blob" },
        file,
        () => {
          this.$set(this.isDownloading, file + id, false);
        }
      );
    },
    getContentItem(obj) {
      let returnVal = "";
      if (obj.ValueSorts) {
        if (obj.ValueSorts[this.currentLanguage]) {
          returnVal = obj.ValueSorts[this.currentLanguage];
        } else {
          returnVal = obj.ValueSorts["Nl"];
        }
      } else {
        returnVal = obj.ValueSort;
      }
      if (Object.prototype.hasOwnProperty.call(obj, "MaxLenght")) {
        if (!returnVal) return returnVal;
        if (
          obj.MaxLenght &&
          returnVal != undefined &&
          returnVal.length > obj.MaxLenghtString &&
          returnVal.length
        ) {
          return this.substringText(obj, returnVal);
        }
      }
      return returnVal;
    },
    /* start functions that are needed for parent component */
    contentReset() {
      this.content = null;
    },
    getSearchVal() {
      return this.searchValue;
    },
    getContenthVal() {
      return this.content;
    },
    getPageFilters() {
      return this.pageFilters;
    },
    getTableSortDescending() {
      return this.tableSortDescending;
    },
    getTableSortBy() {
      return this.tableSortBy;
    },
    /* end functions that are needed for parent component */
  },
};
</script>

<style scoped>
.sort-button {
  margin-top: 5px;
}
.cursor-pointer {
  cursor: pointer;
}
.color-hover {
  cursor: pointer;
}
.color-hover:hover {
  box-shadow: 0px 3px 6px;
}
.backgroundcolor-app {
  background-color: var(--v-backgroundapp-base) !important;
}
@media only screen and (max-width: 495px) {
  .sort-button {
    margin-top: -3px;
  }
}
.sort-button {
  margin-top: 5px;
}

.max-width-layout {
  max-width: 30px;
}
.w-small {
  min-width: 30px !important;
}
.search-field-margin {
  margin-bottom: 6px;
  margin-top: 0px;
}
.search-field-width {
  max-width: 20rem;
}
</style>