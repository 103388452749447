<template>
  <v-dialog v-model="showPreview" max-width="1200">
    <v-card outlined>
      <v-card-title>
        <v-layout justify-space-between>
          {{ showPreviewItem }}
          <v-btn depressed icon color="primaryred" @click="showPreview = false">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-layout>
      </v-card-title>
      <v-card-text>
        <iframe
          v-if="fileAndVideoCheck('.pdf')"
          :src="
            serverUrl +
            '/files/' +
            websiteId +
            '/' +
            showPreviewItem +
            '?token=' +
            userToken
          "
          width="100%"
          height="600px"
          class="mb-4"
        ></iframe>
        <v-layout justify-center v-if="fileAndVideoCheck('.mp4')">
          <video class="mb-4" controls width="100%">
            <source
              :src="
                serverUrl +
                '/files/' +
                websiteId +
                '/' +
                showPreviewItem +
                '?token=' +
                userToken
              "
              type="video/mp4"
            />
          </video>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    websiteId: {
      type: String,
    },
    showPreviewItem: {},
    showPreviewProp: {
      type: Boolean,
    },
  },
  data() {
    return {
      userToken: this.$auth.userToken(),
      serverUrl: this.$helpers.getServerUrl(),
    };
  },
  computed: {
    showPreview: {
      get() {
        return this.showPreviewProp;
      },
      set(val) {
        this.$emit("update:showPreview", val);
      },
    },
  },
  methods: {
    fileAndVideoCheck(type) {
      if (this.showPreviewItem) {
        return this.showPreviewItem.includes(type);
      }
      return false;
    },
  },
};
</script>

<style>
</style>