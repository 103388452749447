<template>
  <div>
    {{ message }}
    <v-btn depressed @click="$emit('syncItems')" text class="text-item">
      {{ $t("Sync items") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ["message"],
};
</script>

<style>
.text-item .v-btn__content {
  color: white;
}
</style>